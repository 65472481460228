export const environment = {
    production: true,
    api: 'https://staging.api.classichub.co.nz',
    buildVersion: '2024.10.4.1',
    uri: 'https://staging.classichub.co.nz',
    envName: 'staging',
    isTest: true,

};

